import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'layout';
import Header from 'components/customer/header';
import Recommendation from 'components/customer/recommendation';
import StartUsing from 'components/ui/startUsing';
import FinalOutcome from 'components/customer/finalOutcome';
import Banner from 'components/usability-testing/banner';
import {
  HeroImage,
  SectionWithRecommendation,
  DescriptionBox,
  StyledSection,
  StyledImg,
} from 'components/styled/CustomerPage';
import Container from 'components/customer/breadcrumbs';

const RandomCoffee = () => {
  const { hero, renaud, post, product1, product2 } = useStaticQuery(graphql`
    query randomcoffeePhotos {
      hero: file(relativePath: { eq: "randomcoffee/hero.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      product1: file(relativePath: { eq: "randomcoffee/product1.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      product2: file(relativePath: { eq: "randomcoffee/product2.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      renaud: file(relativePath: { eq: "persons/renaud_dorval.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      post: markdownRemark(frontmatter: { company: { eq: "RandomCoffee" } }) {
        frontmatter {
          description
          title
          slug
          company
        }
      }
    }
  `);

  const { frontmatter } = post;

  const imgSocial = process.env.GATSBY_HOST + hero.childImageSharp.fluid.src;
  const descriptionSocial = frontmatter.description;
  const { title } = frontmatter;
  const url = '/customers/randomcoffee/';

  const extraMetaTags = [
    { property: 'fb:app_id', content: '926720414193529' },
    { property: 'og:type', content: 'article' },
    { property: 'og:url', content: process.env.GATSBY_HOST + url },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'LiveSession' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@LiveSessionHQ' },
  ];

  if (imgSocial) {
    extraMetaTags.push({ property: 'og:image', content: imgSocial });
    extraMetaTags.push({
      property: 'og:image:secure_url',
      content: imgSocial,
    });
    extraMetaTags.push({ property: 'og:image:width', content: 1287 });
    extraMetaTags.push({ property: 'og:image:height', content: 685 });
    extraMetaTags.push({ name: 'twitter:image', content: imgSocial });
  }

  if (descriptionSocial) {
    extraMetaTags.push({
      property: 'og:description',
      content: descriptionSocial,
    });
    extraMetaTags.push({
      name: 'twitter:description',
      content: descriptionSocial,
    });
  }

  return (
    <Layout
      metaTitle={frontmatter.title}
      titleOverride
      metaDescription={frontmatter.description}
      extraTags={extraMetaTags}
      canonical="/customers/randomcoffee/"
    >
      <div className="container">
        <Container title="RandomCoffee">
          <Header
            title={
              <>
                How did LiveSession help RandomCoffee <span>double</span> campaigns completion
                ratios
              </>
            }
            description="And how they tripled number of clicks on important UI elements"
          />
          <HeroImage
            fluid={hero.childImageSharp.fluid}
            clasName="img-fluid"
            fadeIn
            alt="website - hero"
            title="RandomCoffee team"
            style={{ maxHeight: 500 }}
            objectFit="contain"
          />
          <SectionWithRecommendation>
            <DescriptionBox>
              <h2>Predicting the COVID pandemic?</h2>
              <p>
                RandomCoffee is a visionary solution that proved especially useful during the
                lockdown in times of the COVID pandemic. All companies that care about maintaining
                strong bonds between their employees can help them establish a virtual relationship
                through RandomCoffee campaigns. Among many other functionalities, they allow
                introducing new coworkers to the team, to foster some casual interactions between
                employees from the same building or invite a group of associates to a Virtual
                Coffee.
              </p>
            </DescriptionBox>
            <FinalOutcome>
              <h2>Key achievements</h2>
              <ul>
                <li>
                  <p>UX Design on a deeper level thanks to behavioral knowledge</p>
                </li>
                <li>
                  <p>prioritization of the most critical changes for development</p>
                </li>
                <li>
                  <p>discovery and quick fix of not obvious errors</p>
                </li>
                <li>
                  <p>3 times increase of user clicks on important solution feature</p>
                </li>
                <li>
                  <p>2 times increase of conversion from “started” to “created” campaigns</p>
                </li>
                <li>
                  <p>more reliable application - less support needed</p>
                </li>
                <li>
                  <p>more intuitive interface – less customer training needed</p>
                </li>
              </ul>
            </FinalOutcome>
          </SectionWithRecommendation>

          <StyledSection>
            <h2>Careful choice of necessary software</h2>
            <p>
              Like any innovative and modern company, RandomCoffee organize their work and measure
              their results using the best necessary tools such as Google Analytics, Hubspot,
              Amplitude, Figma, Jira, Confluence, Segment, and many others.
            </p>
            <p>
              As the company’s product had matured, it became clear that they needed session
              tracking software. Their first choice had been not fully satisfying, so after 7 months
              of usage, they decided to switch to LiveSession. This software fulfilled the must-have
              requirements. LiveSession offers many powerful functionalities such as Session
              Replays, Segmentation, and the Inspect Mode allowing the selection of sessions based
              on user activity in a specific section of the application.{' '}
            </p>
            <Banner
              title="Curious about the Inspect Mode functionality?"
              style={{ marginBottom: 0 }}
            />
          </StyledSection>
          <StyledSection>
            <h2>Advancing through Product Lifecycle Stages</h2>
            <p>
              RandomCoffee launched in early 2018. The main goal for developers at that time was to
              implement the designed core functionalities. The focus was on a fast delivery to offer
              a finalized product. Then, it was possible to gather the first feedback from
              customers. Basing on their input, the developers improved the solution in multiple
              iteration cycles. RandomCoffee also used insights from Amplitude to understand user
              behavior. This still was not enough, because they were getting information that
              something was wrong, but no info about why. Unfortunately, there were not enough
              people dedicated just to UX Design at the time.
            </p>
            <StyledImg
              fluid={product1.childImageSharp.fluid}
              fadeIn
              alt="RandomCoffee app - screenshot"
              title="RandomCoffee"
              className="img-fluid"
            />
          </StyledSection>
          <StyledSection>
            <h2>Focusing on the UX Design</h2>
            <p>
              While the product was maturing the RandomCoffee team was growing as well. In 2020, a
              new version of RandomCoffee was announced. At that time, the company hired people who
              could fully focus on design and user behavior analysis. It was crucial to ensure that
              customers are entirely happy with this new version of software. This was the time when
              LiveSession came on stage.
            </p>
          </StyledSection>

          <Recommendation
            recommendation="LiveSession is an impressive solution for those who believe in never-ending continuous improvement. When you know your product is good, but you want to make it even better, then the LiveSession’s insights are priceless."
            person="Renaud Dorval"
            job="Co-Founder"
            company="RandomCoffee"
            url="https://www.random-coffee.com/"
            image={renaud.childImageSharp.fluid}
            style={{ marginBottom: 0 }}
          />
          <StyledSection as="p">
            It was clear that the most desired functionalities were developed. Users should have
            been able to do all tasks they needed. The next product development was not so obvious.
            But it was clear that RandomCoffee needed to ensure everything was running smoothly,
            that there were no unexpected surprises for users, and that the customers were happy
            with how they were achieving their goals.{' '}
          </StyledSection>

          <StyledSection>
            <h2>Discovering the unexpected</h2>
            <p>
              The case of introducing two new features to the solution may be a good example of a
              situation where it is known that something is wrong, but it is not known what or why.
              At the same time, RandomCoffee released an onboarding module, and a feature allowing
              instant connection with a colleague. When they realized that people hardly ever click
              to connect with their colleagues it was a big disappointment.
            </p>

            <Banner title="No more confusion with your users’ behavior!" />
            <p>
              And this was the moment when LiveSession proved to be indispensable. Thanks to the
              Inspect Mode functionality it was possible to sort out the sessions where a specific
              part of the application had been used. It was possible to analyze Rage Clicks. And
              then after analyzing the selected sessions the root cause of the issue became obvious.{' '}
            </p>
          </StyledSection>
          <Recommendation
            recommendation="I love the Inspect Mode and advanced filter options on CSS elements provided by LiveSession. Without these functionalities, RandomCoffee would not have been able to improve the UX Design in such an outstanding way."
            person="Renaud Dorval"
            job="Co-Founder"
            company="RandomCoffee"
            image={renaud.childImageSharp.fluid}
          />
          <StyledSection>
            <p>
              When users used less popular screen resolutions, the onboarding module hid the section
              for instant connection. The developers tested the solution on the most standard
              monitor sizes. The impact, of something popping up in a small window, was not
              anticipated.
            </p>
            <p>
              After reducing the size of the onboarding module, users started using the instant
              connection functionality 3 times more often.
            </p>
          </StyledSection>

          <StyledSection>
            <h2>Less effort and happier customers</h2>
            <p>
              Another challenge for RandomCoffee was to simplify the campaign creation process. They
              noticed that customers often got stuck when creating a complex flow. This required a
              lot of involvement from the support and customer training teams. After replaying
              selected sessions, the designers detected the ergonomic issues and created the list of
              suggested enhancements.
            </p>
            <p>
              After implementing the highest priority improvements, the ratio of finished campaigns
              vs. initiated has doubled. After the design change, creating a campaign became easy
              for customers. They were not the only ones happier. RandomCoffee was also happy
              because they could spend fewer resources supporting and training customers on the
              campaign creation process.
            </p>
            <StyledImg
              fluid={product2.childImageSharp.fluid}
              fadeIn
              alt="RandomCoffee app - screenshot"
              title="RandomCoffee"
              className="img-fluid"
            />
          </StyledSection>

          <StyledSection>
            <h2>Final outcome</h2>
            <p>
              With LiveSession, RandomCoffee has improved their UX Design spectacularly. They were
              able to figure out non-obvious issues associated with different screen resolutions or
              features hiding each other. They were able to triple the use of their core
              functionality and double the number of campaigns that were fully created after their
              initiation.
            </p>
          </StyledSection>
          <Recommendation
            recommendation="I love the Inspect Mode and advanced filter options on CSS elements provided by LiveSession. Without these functionalities, RandomCoffee would not have been able to improve the UX Design in such an outstanding way."
            person="Renaud Dorval"
            job="Co-Founder"
            company="RandomCoffee"
            image={renaud.childImageSharp.fluid}
            style={{ marginBottom: 0 }}
          />
          <StyledSection as="p">
            <p>
              All of the improvements implemented led to a reduction in costs required for customer
              support and training.{' '}
            </p>
          </StyledSection>
        </Container>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default RandomCoffee;
